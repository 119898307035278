import { mergeClasses } from '@blank/utilities'
import Cleave from 'cleave.js/react'
import CrossIcon from 'modules/common/components/_icons/cross.svg'
import {
  ComponentProps,
  FocusEventHandler,
  forwardRef,
  InputHTMLAttributes,
  useState,
} from 'react'
import { Control, FieldError, useWatch } from 'react-hook-form'
import { Button } from '../../Button/Button'
import { HelperText } from '../../HelperText/HelperText'

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: FieldError
  control?: Control<any>
  leftDecorator?: React.ReactNode
  rightDecorator?: React.ReactNode
  helperText?: string
  className?: string
  inputClassName?: string
  clearable?: boolean
  reset?: () => void
  ref?: any
  maskOptions?: ComponentProps<typeof Cleave>['options']
  name: string
  isRequired?: boolean
}

/* eslint-disable max-lines-per-function -- Everything is too interdependent; https://neok.atlassian.net/browse/INFRA-914 */
export const TextField = forwardRef<any, TextFieldProps>(
  (
    {
      label,
      error,
      control,
      leftDecorator,
      rightDecorator,
      helperText: helperTextFromProps,
      className,
      clearable,
      reset,
      inputClassName,
      maskOptions,
      type = 'text',
      isRequired,
      ...inputProps
    },
    ref
  ) => {
    const value = useWatch({
      name: inputProps.name,
      control: control ? control : undefined,
    })

    const { disabled, id, onBlur, onChange, readOnly } = inputProps
    const [isFocused, setIsFocused] = useState(false)

    const labelPosition =
      isFocused || !(value == null || value === '') || readOnly
        ? 'floating'
        : 'static'

    const clear = () => {
      if (!reset) {
        throw new Error(
          'TextField: Cannot clear field if reset prop is missing.'
        )
      }
      reset()
    }

    const classNameInput = mergeClasses(
      'py-3 ring-1 border-0 ring-info-400 appearance-none rounded-lg w-full px-3 focus:outline-none focus:ring-0',
      {
        'bg-info-100 text-info-400 ring-info-200 pointer-events-none cursor-default':
          disabled || readOnly,
        'ring-0': error || isFocused,
        'shadow-textfield-primary': isFocused && !error,
        'shadow-textfield-danger': error,
        'pl-12': leftDecorator,
        'pr-12': rightDecorator || clearable,
        'pr-16': clearable && rightDecorator,
        'py-4': label,
        'pt-6 pb-2': label && labelPosition === 'floating',
      },
      inputClassName
    )

    const onFocus: FocusEventHandler<HTMLInputElement> = (e) => {
      setIsFocused(true)
      inputProps.onFocus?.(e)
    }

    const helperText = error?.message || helperTextFromProps

    return (
      <div className={mergeClasses('relative', className)}>
        {maskOptions ? (
          <Cleave
            htmlRef={ref as any}
            options={maskOptions}
            className={classNameInput}
            value={value} // default value  https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-pass-default-value
            {...inputProps}
            type={type}
            onFocus={onFocus}
            onChange={(e) => {
              e.target.value = e.target.rawValue
              onChange?.(e)
            }}
            onBlur={(e) => {
              setIsFocused(false)
              e.target.value = (e.target as any).rawValue
              onBlur?.(e)
            }}
          />
        ) : (
          <input
            type={type}
            ref={ref}
            className={classNameInput}
            {...inputProps}
            onFocus={onFocus}
            onBlur={(e) => {
              setIsFocused(false)
              onBlur?.(e)
            }}
          />
        )}
        {label && (
          <label
            htmlFor={id}
            className={mergeClasses(
              'label pointer-events-none absolute left-3 mt-4 origin-top-left cursor-text text-base leading-relaxed transition-transform',
              {
                'pointer-events-none cursor-default text-info-400':
                  disabled || readOnly,
                'text-primary': isFocused && !error,
                'text-danger': !!error,
                '-translate-y-2 scale-75 transform-gpu text-info-400':
                  labelPosition === 'floating',
                'left-12': leftDecorator,
              }
            )}
          >
            {label}
            {isRequired && <span className="text-danger-500">&nbsp;*</span>}
          </label>
        )}
        {leftDecorator && (
          <span
            className={mergeClasses(
              'absolute left-3 top-3 flex h-6 w-6 items-center justify-center',
              {
                'top-4': label,
                'text-info-400': disabled || readOnly,
                'text-primary': isFocused && !error,
                'text-danger': !!error,
              }
            )}
          >
            {leftDecorator}
          </span>
        )}
        {clearable && value && (
          <span
            className={mergeClasses(
              'absolute right-3 top-3 flex h-6 w-6 items-center justify-center',
              {
                'top-4': label,
                'right-9': rightDecorator,
                'text-info-400': disabled || readOnly,
              }
            )}
          >
            {
              <Button
                variant="none"
                onClick={(event) => {
                  event.stopPropagation()
                  return clear()
                }}
                className="rounded hover:bg-info-100"
                icon={<CrossIcon className="h-6 w-6" />}
              />
            }
          </span>
        )}
        {rightDecorator && (
          <span
            className={mergeClasses(
              'absolute right-3 top-3 flex h-6 w-6 items-center justify-center',
              {
                'top-4': label,
                'text-info-400': disabled || readOnly,
                'text-primary': isFocused && !error,
                'text-danger': !!error,
              }
            )}
          >
            {rightDecorator}
          </span>
        )}
        {helperText && (
          <HelperText
            color={error?.message ? 'danger' : 'black'}
            text={helperText}
          />
        )}
      </div>
    )
  }
)
