import React from 'react'
import { FieldValues } from 'react-hook-form'
import {
  ContentModalProps,
  useContentModal,
} from '../useContentModal/useContentModal'
import {
  FormModalFooter,
  FormModalFooterProps,
} from './components/FormModalFooter'

export interface FormModalProps<TFieldValues extends FieldValues>
  extends Omit<ContentModalProps, 'footer'>,
    Omit<FormModalFooterProps<TFieldValues>, 'onSecondaryClick'> {
  content: React.ReactNode
  secondaryButtonType?: 'cancel' | 'back'
}

export const useFormModal = <TFieldValues extends FieldValues>({
  form,
  content,
  onBack,
  onCancel,
  secondaryButtonText,
  onSubmit,
  submitButtonText,
  submitButtonDisabled,
  buttonsAlignment,
  hiddenOnError,
  secondaryButtonType = 'cancel',
  onError,
  ...props
}: FormModalProps<TFieldValues>) => {
  const {
    formState: { isValid, errors },
  } = form
  return useContentModal(
    {
      footer: (secondaryButtonText || submitButtonText) && (
        <FormModalFooter
          onSubmit={onSubmit}
          onSecondaryClick={
            secondaryButtonType === 'cancel' ? onCancel : onBack
          }
          secondaryButtonText={secondaryButtonText}
          submitButtonText={submitButtonText}
          submitButtonDisabled={submitButtonDisabled}
          form={form}
          hiddenOnError={hiddenOnError}
          buttonsAlignment={buttonsAlignment}
          onError={onError}
        />
      ),
      // preventDefault is used because form submit is handled by FormModalFooter (outside form)
      content: <form onSubmit={(e) => e.preventDefault()}>{content}</form>,
      onCancel,
      onBack,
      ...props,
    },
    [form, isValid, errors]
  )
}
