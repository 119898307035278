import { useMutation } from '@tanstack/react-query'
import { useFreshToken } from 'modules/auth/hooks/useFreshToken'
import {
  apiClient,
  COUNTRY_API_BASE_URL_MAP,
} from 'modules/common/http/apiClient'
import {
  COUNTRY_SCA_API_BASE_URL_MAP,
  scaApiClient,
} from 'modules/common/http/scaApiClient'
import { getAuthenticatedUser } from '../lib/auth0/token'
import { MutationOptions } from '../types/mutation'

export const useMutationWithAuth = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>({
  mutationFn,
  ...options
}: MutationOptions<TData, TError, TVariables, TContext>) => {
  const getFreshToken = useFreshToken()

  return useMutation<TData, TError, TVariables, TContext>({
    mutationFn: async (variables) => {
      const accessToken = await getFreshToken()

      if (!accessToken) {
        throw new Error(`useMutationWithAuth: Missing Access Token`)
      }

      const { country } = getAuthenticatedUser(accessToken)

      // Set default base URL depending on the user country
      apiClient.defaults.baseURL = COUNTRY_API_BASE_URL_MAP[country]
      scaApiClient.defaults.baseURL = COUNTRY_SCA_API_BASE_URL_MAP[country]

      return mutationFn({ accessToken, ...variables })
    },
    ...options,
  })
}
