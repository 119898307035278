import { mergeClasses } from '@blank/utilities'
import { isString } from 'lodash'
import { Trans } from 'next-i18next'
import { ReactNode } from 'react'
import {
  IconCrossRounded,
  IconDanger,
  IconInformationSquare,
} from '../../_icons/icons'

export const MESSAGE_TYPES = [
  'info',
  'important',
  'important-secondary',
  'warning',
  'error',
] as const

type MessageType = (typeof MESSAGE_TYPES)[number]

export interface MessageProps {
  className?: string
  title?: string
  content: string | ReactNode
  type?: MessageType
  withIcon?: boolean
  icon?: ReactNode
}

const MESSAGE_ICONS: {
  [key in MessageType]: ReactNode
} = {
  info: <IconInformationSquare />,
  important: <IconInformationSquare />,
  ['important-secondary']: <IconInformationSquare />,
  warning: <IconDanger />,
  error: <IconCrossRounded />,
}

export const Message = ({
  className,
  title,
  content,
  type = 'info',
  withIcon = false,
  icon,
}: MessageProps) => {
  return (
    <div
      className={mergeClasses(
        'flex items-center rounded-lg p-2 text-sm',
        {
          'bg-info-90 text-info': type === 'info',
          'bg-primary-90 text-primary': type === 'important',
          'bg-white text-primary': type === 'important-secondary',
          'bg-warning-90 text-warning': type === 'warning',
          'bg-danger-90 text-danger': type === 'error',
        },
        className
      )}
      data-testid={`${type}-message`}
    >
      {withIcon && (
        <span className="mr-2 self-start">{icon || MESSAGE_ICONS[type]}</span>
      )}
      <div className="h-full w-full">
        {title && (
          <Trans parent="p" className="font-bold">
            {title}
          </Trans>
        )}
        {isString(content) ? <Trans parent="p">{content}</Trans> : content}
      </div>
    </div>
  )
}
