import { mergeClasses } from '@blank/utilities'
import { Trans } from 'next-i18next'

interface Props {
  color?: 'black' | 'danger'
  text: string
}

export const HelperText = ({ color = 'black', text }: Props) => {
  return (
    <Trans
      className={mergeClasses('pl-3 pt-1 text-left text-xs', {
        'text-info-600': color === 'black',
        'text-danger': color === 'danger',
      })}
      parent="p"
    >
      {text}
    </Trans>
  )
}
