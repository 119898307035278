import { Button } from 'modules/common/components/_ui/Button/Button'
import { useHideModalContext } from 'modules/common/contexts/HideModalContext'
import { useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'

export interface FormModalFooterProps<TFieldValues extends FieldValues> {
  onSecondaryClick?: () => unknown
  secondaryButtonText?: string
  submitButtonText?: string
  submitButtonDisabled?: boolean
  buttonsAlignment?: 'center' | 'stretch'
  form: UseFormReturn<TFieldValues>
  onError?: () => unknown
  hiddenOnError?: boolean
  onSubmit?: (data: TFieldValues) => unknown
}

export const FormModalFooter = <TFieldValues extends FieldValues>({
  onSecondaryClick,
  secondaryButtonText,
  submitButtonText,
  submitButtonDisabled,
  buttonsAlignment = 'stretch',
  form,
  onSubmit,
  onError,
  hiddenOnError = false,
}: FormModalFooterProps<TFieldValues>) => {
  const [isLoading, setIsLoading] = useState(false)
  const { hideModal } = useHideModalContext()

  const buttonClassName =
    buttonsAlignment === 'stretch'
      ? 'flex-auto min-w-full sm:min-w-0 sm:px-10'
      : ''

  const submitForm = async (data: any) => {
    setIsLoading(true)
    try {
      onSubmit && (await onSubmit(data))
      hideModal()
    } catch {
      onError && (await onError())
      hiddenOnError && hideModal()
    } finally {
      setIsLoading(false)
    }
  }

  const { isValid, errors } = form.formState

  return (
    <>
      {secondaryButtonText && (
        <Button
          variant="secondary"
          color="default"
          type="button"
          onClick={async () => {
            await onSecondaryClick?.()
            hideModal()
          }}
          data-testid="form-modal-cancel-button"
          className={buttonClassName}
        >
          {secondaryButtonText}
        </Button>
      )}
      {submitButtonText && (
        <Button
          disabled={
            !isValid || Object.keys(errors).length > 0 || submitButtonDisabled
          }
          variant="primary"
          color="default"
          type="button"
          className={buttonClassName}
          isLoading={isLoading}
          data-testid="form-modal-submit-button"
          onClick={form.handleSubmit(submitForm)}
        >
          {submitButtonText}
        </Button>
      )}
    </>
  )
}
